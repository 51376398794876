<template>
  <ts-card>
    <div class="form-header ts-bg-gray-100 ts-border-b ts-border-gray-400 ts-p-4 ts-relative">
      <h4 class="ts-text-sm">{{ $t('reportBasicSalaryHistory.previewPdf') }}</h4>
      <ts-modal-close-button />
    </div>
    <ts-card-wrapper>
      <div id="container">
        <i class="fa fa-spinner spin fa-2x" v-if="previewing"></i>
        <iframe :src="src" v-if="!previewing"></iframe>
      </div>
    </ts-card-wrapper>
  </ts-card>
</template>

<script>
export default {
  name: 'previewPdf',
  props: ['org_chart_structure_id', 'cycle_year'],
  data() {
    return {
      previewing: false,
      src: ''
    }
  },
  created() {
    this.previewPdf()
  },
  methods: {
    previewPdf() {
      this.previewing = true
      this.$store
        .dispatch('hr/reportBasicSalaryHistory/exportPdf', {
          org_chart_structure_id: this.org_chart_structure_id,
          cycle_year: this.cycle_year
        })
        .then(response => {
          console.log(response)
          this.$toasted.success(response.message)
          this.previewing = false
          this.src = response.url
        })
        .catch(error => {
          this.$toasted.error(error.message)
          this.previewing = false
        })
    }
  }
}
</script>

<style scoped>
#container {
  width: 100%;
  height: 70vh;
  background: black;
  text-align: center;
}
#container iframe {
  width: 100%;
  height: 70vh;
  border: 2px solid #acacac;
}
#container i {
  margin-top: 32vh;
}
</style>
