<template>
    <div>
        <div class="row mb-3">
            <div class="col-md-12">
                <label class="required">{{ $t('workSchedule.shift') }}</label>
                <ts-loading-banner :loading="ready_fetch">
                    <select
                        :class="{
                            'is-invalid': errors.has('shift_id')
                        }"
                        v-model="model.shift_id"
                        class="form-select"
                    >
                        <option
                            value
                            disabled
                            selected
                            style="display: none;"
                            >{{ $t('select') }}</option
                        >
                        <option
                            v-for="(shift, index) in shifts"
                            :key="index"
                            :value="shift.shift_id"
                            >{{ shift.shift_name }} ({{
                                shift.shift_type
                            }})</option
                        >
                    </select>
                    <div class="invalid-feedback" v-if="errors.has('shift_id')">
                        {{ errors.first('shift_id') }}
                    </div>
                </ts-loading-banner>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6">
                <label class="required">{{
                    $t('workSchedule.yearFrom')
                }}</label>
                <DatePicker
                    type="year"
                    placeholder="Select year"
                    v-model="model.cycle_year_from"
                    format="yyyy"
                    @on-change="onChangeYearFrom"
                    style="width: 100%"
                ></DatePicker>
                <div
                    class="text-danger tw-text-xs"
                    v-if="errors.has('cycle_year_from')"
                >
                    {{ errors.first('cycle_year_from') }}
                </div>
            </div>
            <div class="col-md-6">
                <label class="required">{{
                    $t('workSchedule.monthFrom')
                }}</label>
                <DatePicker
                    type="month"
                    placeholder="Select year"
                    v-model="model.cycle_month_from"
                    format="MM"
                    @on-change="onChangeMonthFrom"
                    style="width: 100%"
                ></DatePicker>
                <div
                    class="text-danger tw-text-xs"
                    v-if="errors.has('cycle_month_from')"
                >
                    {{ errors.first('cycle_month_from') }}
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6">
                <label class="required">{{ $t('workSchedule.yearTo') }}</label>
                <DatePicker
                    type="year"
                    placeholder="Select year"
                    v-model="model.cycle_year_to"
                    format="yyyy"
                    @on-change="onChangeYearTo"
                    style="width: 100%"
                ></DatePicker>
                <div
                    class="text-danger tw-text-xs"
                    v-if="errors.has('cycle_year_to')"
                >
                    {{ errors.first('cycle_year_to') }}
                </div>
            </div>
            <div class="col-md-6">
                <label class="required">{{ $t('workSchedule.monthTo') }}</label>
                <DatePicker
                    type="month"
                    placeholder="Select year"
                    v-model="model.cycle_month_to"
                    format="MM"
                    @on-change="onChangeMonthTo"
                    style="width: 100%"
                ></DatePicker>
                <div
                    class="text-danger tw-text-xs"
                    v-if="errors.has('cycle_month_to')"
                >
                    {{ errors.first('cycle_month_to') }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 tw-text-right tw-space-x-2">
                <ts-button @click.prevent="$emit('close')" :disabled="saving">{{
                    $t('cancel')
                }}</ts-button>
                <ts-button
                    class="btn-primary ml-2"
                    :waiting="saving"
                    @click.prevent="save"
                >
                    {{ $t('save') }}
                </ts-button>
            </div>
        </div>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'
import moment from 'moment'
import { mapState } from 'vuex'

export default {
    name: 'copy-work-schedule-form',
    data () {
        return {
            model: {
                shift_id: '',
                cycle_year_from: moment()
                    .add(-1, 'months')
                    .format('YYYY'),
                cycle_month_from: moment()
                    .add(-1, 'months')
                    .format('MM'),
                cycle_year_to: moment().format('YYYY'),
                cycle_month_to: moment().format('MM')
            },
            errors: new Errors(),
            saving: false,
            ready_fetch: false
        }
    },
    created () {
        this.fetchResource()
    },
    computed: {
        ...mapState('humanResource/workSchedule', ['shifts'])
    },
    methods: {
        fetchResource () {
            this.ready_fetch = true
            this.$store
                .dispatch('humanResource/workSchedule/getShift')
                .then(() => {
                    this.ready_fetch = false
                })
                .catch(error => {
                    this.ready_fetch = false
                    this.notice({ type: 'error', text: error.message })
                })
        },
        save () {
            this.errors = new Errors()
            this.saving = true
            this.$store
                .dispatch('humanResource/workSchedule/copy', this.model)
                .then(response => {
                    this.saving = false
                    this.$emit('close')
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                })
                .catch(error => {
                    this.saving = false
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
        },
        onChangeYearFrom (year) {
            this.model.cycle_year_from = year
        },
        onChangeYearTo (year) {
            this.model.cycle_year_to = year
        },
        onChangeMonthFrom (month) {
            this.model.cycle_month_from = month
        },
        onChangeMonthTo (month) {
            this.model.cycle_month_to = month
        },
        clearInput () {
            this.model = {
                shift_id: '',
                cycle_year_from: moment().format('YYYY'),
                cycle_month_from: moment()
                    .add(-1, 'months')
                    .format('MM'),
                cycle_year_to: moment().format('YYYY'),
                cycle_month_to: moment().format('MM')
            }
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'WORK SCHEDULE',
                desc: not.text
            })
        }
    }
}
</script>
